import React, {useState} from "react";
import './listItem.css';
import { Link } from "react-router-dom";

function PaymentItem(props) {
    const noAction = (e) => {
        e.preventDefault();
    }
  return (
    <Link to="/pay" onClick = {!props.display ? noAction : () => {}} style = {{textDecoration:"none"}}>
    <div className={!props.display ? "list-item disabled" : "list-item"}>
      <div className = "left">
        <div className="icon" style={{ backgroundImage: `url("${props.icon}")`, backgroundRepeat: 'no-repeat', 
    backgroundPosition: 'center'}}>
        </div>
        <div style = {{color: !props.display ? "#bababa" : "#677381"}}className="item-desc">
          {props.text}
        </div>
      </div>
      <div className="right">
        {props.display && <div style = { props.statusIcon ? {backgroundImage: "url(images/validate_small.png)"} : {}}className="status">
          </div>}
        <div className="next">
        </div>
      </div>
    </div>
    </Link>
  );
}

export default PaymentItem;
