import './listItem.css';
import ListItem from "./ListItem"
import PaymentItem from "./PaymentItem"
function List(props) {
  return (
    <div className="">
        <ListItem  fct = {props.fct} id="item2" display={props.display.display2} text={props.text.text2} statusIcon = {props.statusIcons.statusIcon2} icon="/images/timer.svg"/>
        <ListItem  fct = {props.fct} id="item3" display={props.display.display3} text={props.text.text3} statusIcon = {props.statusIcons.statusIcon3} icon="/images/pass.svg"/>
        <ListItem  fct = {props.fct} id="item4" display={props.display.display4} text={props.text.text4} statusIcon = {props.statusIcons.statusIcon4} icon="/images/car_newStart.svg"/>
        {/* <ListItem  fct = {props.fct} id="item5" display={props.display.display5} text={props.text.text5} statusIcon = {props.statusIcons.statusIcon5} icon="/images/bt_options_tarjeta.svg"/> */}
        <PaymentItem fct = {props.fct} id="item5" display={props.display.display5} text={props.text.text5} statusIcon = {props.statusIcons.statusIcon5} icon="/images/bt_options_tarjeta.svg"/>
    </div>
  );
}

export default List;
