import React, {useState} from "react";
import '../App.css';
import MyModal from "../Components/MyModal"

function Park(props) {
    const fct = (date)  => {
      props.fct(date);
    }
    return (
     <div className="App">
      <a href="./" style = {{display:"flex", alignItems:"center", justifyContent:"center", paddingTop:"10px"}}><img alt="logo" class="whiteLogo" src="/images/logo-main.svg" style={{width:"88px"}}/></a>
       <MyModal fct={fct}/>
     </div>
     )

}

export default Park;