import { React, useState } from 'react'


export default function Loading() {
    return(
        <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:"100vh"}}>
            <h2>Loading...
            </h2>
            </div>
    )
}