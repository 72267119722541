import React, {useState} from "react";
import './listItem.css';
function ListItem(props) {

  const handleClick = () => {
    if (props.display) props.fct(props.id);
  }

  return (
    <div onClick = {handleClick} className={!props.display ? "list-item disabled" : "list-item"}>
      <div className = "left">
        <div className="icon" style={{ backgroundImage: `url("${props.icon}")`, backgroundRepeat: 'no-repeat', 
    backgroundPosition: 'center'}}>
        </div>
        <div style = {{color: !props.display ? "#bababa" : "#677381"}}className="item-desc">
          {props.text}
        </div>
      </div>
      <div className="right">
        {props.display && <div style = { props.statusIcon ? {backgroundImage: "url(images/validate_small.png)"} : {}}className="status">
          </div>}
        <div className="next">
        </div>
      </div>
    </div>
  );
}

export default ListItem;
